// @flow

export default function createSVGIcon(icon: string): SVGElement {
  const svgNS = 'http://www.w3.org/2000/svg';
  const xlinkNS = 'http://www.w3.org/1999/xlink';
  const svg = document.createElementNS(svgNS, 'svg');
  svg.setAttributeNS(null, 'class', 'icon');
  const use = document.createElementNS(svgNS, 'use');
  use.setAttributeNS(xlinkNS, 'xlink:href', '#icon-' + icon);
  svg.appendChild(use);
  return ((svg: any): SVGElement);
}
