/*@flow*/
import isochrone, { type Params } from 'mapbox-isochrone';

export default (
  startPosition: [number, number],
  params: Params
): Promise<GeoJSON$FeatureCollection<GeoJSON$Polygon>> =>
  new Promise((res, rej) => {
    isochrone(startPosition, params, (err, output) => {
      if (err) {
        rej(err);
      } else if (output) {
        res(output);
      } else {
        rej('Unexpected error.');
      }
    });
  });
