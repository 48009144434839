/*@flow*/

export const error = (...args: any[]) => {
  if (window.Rollbar) {
    const rollbarError = window.Rollbar.error;
    rollbarError(...args);
  } else {
    console.error(...args);
  }
};

export default { error };
