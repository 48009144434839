/*@flow*/
import useStoreState from 'lib/useStoreState';
import * as commands from 'store/commands';
import positionWatcher from 'lib/positionWatcher';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { CENTER_OF_LIC, LIC_POLYGON } from 'store/constants';
import { useEffect } from 'react';
import * as turfHelpers from '@turf/helpers';

const licPolygon = turfHelpers.feature(LIC_POLYGON);

export default function useIsochrone() {
  const { mapCenter } = useStoreState({
    mapCenter: 'Map.center',
  });

  useEffect(() => {
    if (mapCenter) {
      commands.showIsochroneForTransportationMode('walking');
    } else {
      const go = (lat, lng) => {
        commands.setMapCenter({ lat, lng });
        commands.showIsochroneForTransportationMode('walking');
      };
      positionWatcher()
        .getPosition()
        .then(pos => {
          const { latitude, longitude } = pos.coords;
          const point = turfHelpers.point([longitude, latitude]);
          const isInLic = booleanPointInPolygon(point, licPolygon);
          if (isInLic) {
            go(latitude, longitude);
          } else {
            const [lng, lat] = CENTER_OF_LIC;
            go(lat, lng);
          }
        })
        .catch(e => {
          window.onerror(e);
          const [lng, lat] = CENTER_OF_LIC;
          go(lat, lng);
        });
    }

    commands.setSectionName('Explore');

    return () => {
      commands.clearIsochroneData();
      commands.clearBoundsFilter();
    };
  }, [mapCenter]);
}
