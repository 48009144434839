/*@flow*/
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import useTitle from 'lib/useTitle';
import useRouter from 'lib/useRouter';
import useStoreState from 'lib/useStoreState';
import { Route, Link } from 'react-router-dom';
import Drawer from './Drawer';
import Show from 'comps/Pages/Show';
import IndexMap from './Map';
import Navbar from 'comps/App/Navbar';
import Toaster from 'comps/App/Toaster';
import * as commands from 'store/commands';
// $FlowFixMe
import { ReactComponent as Logo } from 'images/logo_pages.svg';
import { DRAWER_HEIGHT, SEARCH_HEIGHT, RIDGE_HEIGHT } from 'store/constants';

const DRAWER_SHADOW_HEIGHT = '10px';
const MAP_HEIGHT = `calc(100vh - ${DRAWER_HEIGHT.toString()})`;
const DRAWER_CLOSED_MAP_HEIGHT = `calc(100vh - ${SEARCH_HEIGHT.toString()} - ${RIDGE_HEIGHT.toString()} + ${DRAWER_SHADOW_HEIGHT})`;

// ---------------------------------------

const Page = styled.div(() => {
  return `
    height: 100vh;
    overflow: hidden;
    position: relative;
    overscroll-behavior: none;
  `;
});

const Container = styled.div((props) => {
  return `
    display: ${props.isExact ? 'block' : 'none'};
    overscroll-behavior: none;
  `;
});

function useQuerySync() {
  const { match, history } = useRouter();

  useEffect(() => {
    commands.setFiltersFromParam(history.location);
  }, []);

  useEffect(() => {
    commands.setFiltersFromParam(history.location);
  }, [match.isExact]);
}

function useHeightSync(height) {
  useEffect(() => commands.mapDidResize(), [height]);
}

type PropsT = $Exact<{ decks?: Object[] }>;

const Pages = (props: PropsT) => {
  useTitle('LIC Local - Pages');

  const { mapIsDown } = useStoreState({
    mapIsDown: 'Map.isDown',
  });

  const height = mapIsDown ? DRAWER_CLOSED_MAP_HEIGHT : MAP_HEIGHT;

  useQuerySync();
  useHeightSync(height);

  const { match } = useRouter();

  return (
    <Page>
      <Container isExact={match.isExact}>
        <Navbar fixed textColor="var(--blue)">
          <Link to="/">
            <Logo />
          </Link>
        </Navbar>
        <IndexMap height={height} />
        <Drawer />
        <Toaster />
      </Container>
      <Route exact path="/pages/:id" component={Show} />
    </Page>
  );
};

export default Pages;
