/*@flow*/

export const CategoryColorMap = {
  'Food & Drink': '#da6c6f',
  'Arts & Culture': '#9381ff',
  'Health & Fitness': '#06d6a0',
  Shopping: '#3c91e6',
  'Perks Program': '#68b37d',
};

export default (value: $Keys<typeof CategoryColorMap>) => {
  return CategoryColorMap[value];
};
