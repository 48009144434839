/*@flow*/
export const SET_RECORD = 'SET_RECORD';

function orderLevels(lvls: string[]): { [key: string]: number } {
  const CAP = 100;
  return lvls.reduce(
    (s, v, i) => ({ ...s, [v]: (lvls.length - i) * CAP + 5000 }),
    {}
  );
}

export const DEFAULT_FILTERS = {
  category: ['Arts & Culture', 'Food & Drink', 'Health & Fitness', 'Shopping'],
};

export const LEVEL = orderLevels([
  'SIDEBAR',
  'NAVBAR',
  'FILTERS_PAGE',
  'SHOW_PAGE',
  'TOASTER',
  'SPINNER',
  'DRAWER',
  'MAP',
  'SCROLLER',
]);

export const CENTER_OF_LIC = [-73.94887131488494, 40.7453322657858];

const mkValueObject = (value, unit) => {
  return new (class {
    value = value;
    unit = unit;
    toString = () => {
      return this.value + this.unit;
    };
  })();
};

export const DRAWER_HEIGHT = mkValueObject(265, 'px');
export const SEARCH_HEIGHT = mkValueObject(44, 'px');
export const RIDGE_HEIGHT = mkValueObject(56, 'px');

export const LIC_POLYGON = {
  type: 'Polygon',
  coordinates: [
    [
      [-73.928408, 40.776902],
      [-73.923944, 40.774659],
      [-73.917848, 40.770304],
      [-73.914628, 40.769394],
      [-73.904325, 40.767444],
      [-73.903767, 40.765494],
      [-73.908189, 40.759936],
      [-73.90982, 40.757855],
      [-73.910894, 40.758343],
      [-73.912697, 40.756002],
      [-73.910639, 40.753044],
      [-73.909866, 40.751646],
      [-73.912356, 40.751191],
      [-73.916993, 40.750281],
      [-73.922917, 40.749078],
      [-73.925321, 40.748655],
      [-73.925793, 40.746021],
      [-73.926175, 40.743811],
      [-73.926819, 40.740982],
      [-73.927678, 40.736657],
      [-73.92991, 40.737535],
      [-73.931499, 40.736852],
      [-73.934418, 40.735519],
      [-73.934332, 40.734836],
      [-73.936951, 40.73373],
      [-73.931198, 40.729113],
      [-73.931456, 40.72869],
      [-73.935233, 40.72921],
      [-73.939269, 40.731617],
      [-73.94017, 40.734218],
      [-73.944077, 40.73695],
      [-73.948498, 40.738348],
      [-73.954028, 40.739746],
      [-73.956475, 40.739714],
      [-73.958707, 40.738933],
      [-73.960768, 40.73812],
      [-73.962142, 40.738445],
      [-73.962142, 40.739811],
      [-73.961283, 40.742673],
      [-73.957076, 40.749013],
      [-73.948237, 40.758635],
      [-73.941712, 40.766469],
      [-73.93583, 40.769427],
      [-73.934714, 40.771734],
      [-73.937376, 40.772449],
      [-73.937633, 40.775114],
      [-73.936217, 40.777162],
      [-73.9348, 40.777974],
      [-73.933298, 40.778137],
      [-73.928408, 40.776902],
    ],
  ],
};
