/*@flow*/
import hash from 'lib/hash';
import colorSchemes from './colorSchemes';
import { useEffect, useState } from 'react';
import useStoreState from 'lib/useStoreState';
import * as commands from 'store/commands';

export default function useTrackDecks() {
  const [decks, setDecks] = useState([]);
  const [isPristine, setPristine] = useState(true);
  const { hullsData } = useStoreState({
    hullsData: 'Map.isochrone.hulls.data',
  });

  useEffect(() => {
    if (!hullsData) return;
    // Add them just once
    if (!isPristine) return;

    const decks2 = [
      {
        id: 'track-bounds',
        type: (_config: any, map: any) => {
          const updateFilter = () => commands.setFilterFromMapBounds();
          return {
            enable() {
              updateFilter();
              map.on('dragend', updateFilter);
            },
            update(_: any) {},
            disable() {
              map.off('dragend', updateFilter);
            },
          };
        },
      },
      {
        id: 'hulls',
        type: 'source',
        config: {
          type: 'geojson',
          data: hullsData,
        },
      },
      {
        id: 'quantized',
        type: 'layer',
        config: {
          id: 'quantized',
          type: 'line',
          source: 'hulls',
          paint: {
            'line-color': {
              property: 'time',
              type: 'interval',
              stops: colorSchemes.pendleton.map<[number, string]>(([a, b]) => [
                a - 300,
                b,
              ]),
            },
            'line-opacity': 0.25,
            'line-width': {
              base: 1.5,
              stops: [[10, 1], [22, 4]],
            },
          },
        },
      },
      {
        id: 'quantized-major',
        type: 'layer',
        config: {
          id: 'quantized-major',
          type: 'line',
          source: 'hulls',
          filter: ['>=', 'quantized', 600],
          paint: {
            'line-color': {
              property: 'time',
              type: 'interval',
              stops: colorSchemes.pendleton,
            },
            'line-width': {
              base: 4,
              stops: [[10, 1.5], [22, 15]],
            },
          },
        },
      },
      {
        id: 'quantized-label',
        type: 'layer',
        config: {
          id: 'quantized-label',
          type: 'symbol',
          source: 'hulls',
          filter: ['>=', 'quantized', 600],
          layout: {
            'text-field': '{minutes} MIN',
            'text-font': ['DIN Offc Pro Bold'],
            'symbol-placement': 'line',
            'text-allow-overlap': true,
            'text-padding': 1,
            'text-max-angle': 90,
            'text-size': {
              base: 1.2,
              stops: [[8, 12], [22, 30]],
            },
            'text-letter-spacing': 0.1,
          },
          paint: {
            'text-halo-color': 'hsl(55, 100%, 100%)',
            'text-color': {
              property: 'time',
              type: 'interval',
              stops: colorSchemes.pendleton,
            },
            'text-halo-width': 3,
          },
        },
      },
      {
        id: 'userLocationMarker',
        type: 'userLocationMarker',
        events: {
          ready: () => {
            const selector = '.mapboxgl-user-location-dot svg';
            const nodes = document.querySelectorAll(selector);
            if (nodes) {
              nodes.forEach(node => node.setAttribute('fill', 'white'));
            }
          },
        },
      },
    ];

    setPristine(false);
    setDecks(decks2);
  }, [hash(hullsData)]);

  return decks;
}
