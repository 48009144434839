/*@flow*/
/** @jsx jsx */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import QuerySync from 'comps/App/QuerySync';
import Counter from './Counter';
import Container from './Container';
// $FlowFixMe
import { ReactComponent as Logo } from 'images/logo_pages.svg';
import cx from 'classnames';
import SubcategoryStack from './Place/SubcategoryStack';
import TagStack from './Place/TagStack';
import * as commands from 'store/commands';
import * as I from 'immutable';
import { jsx, css } from '@emotion/core';
import categoryToColor from 'comps/Pages/Filters/categoryToColor';
import useStoreState from 'lib/useStoreState';
import useRouter from 'lib/useRouter';

const buttonStyle = (
  active: boolean,
  color: string = 'var(--primary)',
  category: string = ''
) => `
  box-shadow: none !important;
  color: ${!active ? 'var(--primary)' : 'white'};
  background: ${active ? color : 'white'};
  border: solid 1px ${active ? color : 'var(--primary)'};
  text-decoration: none;
  &:hover {
    border-color: ${active ? color : 'var(--primary)'} !important;
    color: ${active ? 'white' : 'var(--primary)'} !important;
  }
  font-size: ${category.length > 15 ? '0.95rem' : '1rem'};
`;


const ButtonFactory = (fn) => (props) => {
  const { needle, haystack } = props;
  return (
    <button
      css={css(
        buttonStyle(haystack.includes(needle), categoryToColor(needle), needle)
      )}
      className="btn"
      onClick={() =>
        fn(
          haystack.includes(needle)
            ? haystack.filter((v) => v !== needle)
            : haystack.concat([needle])
        )
      }
    >
      {needle}
    </button>
  );
};
const CategoryButton = ButtonFactory(commands.setCategories);

const RecordTypeTabs = ({ flushTop }: { flushTop: boolean }) => {
  const { history } = useRouter();
  const { query = {} } = history.location;
  const { recordType } = useStoreState({
    recordType: 'filters.type.0',
  });

  useEffect(() => {
    // Add dest if it's missing
    !query.dest &&
      history.push({ pathname: '/filters', query: { dest: '/find' } });
  }, [query.dest]);

  useEffect(() => {
    let dest = '/find';
    if (recordType === 'Event') {
      dest = '/events';
    }
    history.push({ pathname: '/filters', query: { dest } });
  }, [recordType]);

  const commonBtnStyles = `
      color: var(--primary);
      background: white;
      box-shadow: none !important;
    `;
  const smallBtnStyles = (props) => `
      ${commonBtnStyles}
      border-radius: 0;
      height: initial;
      ${
        props.selected
          ? 'border-color: white; border-bottom-color: var(--primary);'
          : ''
      };
    `;

  const bigBtnStyles = () => `
      ${commonBtnStyles}
      border: solid 1px var(--primary);
      height: 150px;
      transition: border 400ms, height 400ms;
    `;

  const isTenant = recordType === 'Tenant';
  const isEvent = recordType === 'Event';

  let placeBtnStyles;
  let eventBtnStyles;
  if (flushTop) {
    placeBtnStyles = smallBtnStyles({ selected: isTenant });
    eventBtnStyles = smallBtnStyles({ selected: isEvent });
  } else {
    placeBtnStyles = bigBtnStyles();
    eventBtnStyles = bigBtnStyles();
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: ' grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: '10px',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      >
        <button
          className={cx('btn', {
            'btn-link': recordType !== 'Tenant',
          })}
          css={css(placeBtnStyles)}
          onClick={() => {
            commands.setRecordTypes('Place');
            // TODO: update destination
          }}
        >
          Places
        </button>
        <button
          className={cx('btn', {
            'btn-link': recordType !== 'Event',
          })}
          css={css(eventBtnStyles)}
          onClick={() => {
            commands.setRecordTypes('Event');
            // TODO: update destination
          }}
        >
          Events
        </button>
      </div>
      {flushTop && <hr />}
    </React.Fragment>
  );
};

const FirstSection = () => {
  let { category = I.List(), recordType } = useStoreState({
    category: 'filters.businesses.category',
    recordType: 'filters.type.0',
  });
  const { history } = useRouter();
  const { query = {} } = history.location;
  category = ((category.toJS(): any): string[]);

  if (query.dest === '/explore') {
    recordType = 'Tenant';
  }
  if (recordType !== 'Tenant') return null;

  return (
    <div>
      <div
        css={{
          display: ' grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '50px 50px',
          marginTop: '20px',
          gridRowGap: '5px',
          gridColumnGap: '5px',
        }}
      >
        <CategoryButton haystack={category || []} needle="Arts & Culture" />
        <CategoryButton haystack={category || []} needle="Food & Drink" />
        <CategoryButton haystack={category || []} needle="Health & Fitness" />
        <CategoryButton haystack={category || []} needle="Shopping" />
      </div>
      {category.length > 0 && (
        <div
          css={css(`
                display: grid;
                grid-template-columns: 0.5fr;
                grid-template-rows: 50px;
                margin-top: 5px;
                margin-bottom: 20px;
                grid-row-gap: 5px;
                grid-column-gap: 5px;
                justify-content: center;
              `)}
          className="align-items-end text-center"
        >
        </div>
      )}
    </div>
  );
};

const SubcategorySection = () => {
  const { history } = useRouter();
  let { recordType, category = I.List(), resultCount } = useStoreState({
    recordType: 'filters.type.0',
    category: 'filters.businesses.category',
    resultCount: ['Counter.value', 0],
  });

  const { query = {} } = history.location;
  if (query.dest === '/explore') return null;
  if (recordType !== 'Tenant') return null;
  if (category.isEmpty()) return null;
  if (resultCount === 0) return null;

  return (
    <div
      css={css(`
          margin-top: 20px;
          border-top: 1px solid rgba(0, 0, 0, .1);
          padding-top: 20px;

          .button-stack {
            display: grid;
          }
          button {
            padding: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
      `)}
    >
      <SubcategoryStack />
      <hr />
    </div>
  );
};

const TagsSection = () => {
  let { recordType } = useStoreState({
    recordType: 'filters.type.0',
  });
  const { history } = useRouter();
  const { query = {} } = history.location;

  let title;
  if (query.dest === '/explore') {
    recordType = 'Tenant';
  } else {
    return null;
  }
  if (recordType !== 'Tenant') return null;
  return (
    <div
      css={css(`
            .button-stack {
              display: grid;
            }
            button {
              padding: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
        `)}
    >
      {title}
      <TagStack />
    </div>
  );
};

const EventSection = () => {
  const { happensOn } = useStoreState({
    happensOn: 'filters.events.happensOn',
  });
  return (
    <div
      style={{
        display: ' grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        marginTop: '20px',
        marginBottom: '20px',
        gridColumnGap: '8px',
      }}
    >
      <button
        css={css(buttonStyle(happensOn === 'Today'))}
        className="btn"
        onClick={() => commands.setHappensOn('Today')}
      >
        Today
      </button>
      <button
        css={css(buttonStyle(happensOn === 'Weekend'))}
        className="btn"
        onClick={() => commands.setHappensOn('Weekend')}
      >
        Weekend
      </button>
      <button
        css={css(buttonStyle(happensOn === 'This Month'))}
        className="btn"
        onClick={() => commands.setHappensOn('This Month')}
      >
        This Month
      </button>
    </div>
  );
};

const Filters = () => {
  const { location } = useRouter();
  const m = {
    Event: 'events',
    Tenant: 'places',
  };
  const {
    recordType,
    happensOn,
    category = I.List(),
    tags = I.List(),
  } = useStoreState({
    recordType: 'filters.type.0',
    happensOn: 'filters.events.happensOn',
    category: 'filters.businesses.category',
    tags: 'filters.businesses.tags',
  });

  useEffect(() => {
    commands.clearLocationFilters();
  }, []);

  const friendlyRecordType = recordType in m ? m[recordType] : '';
  const dest = (location.query: any).dest;
  const isFind = dest === '/find';

  const isEvent = recordType === 'Event';
  let flushTop = true;
  if (isFind) {
    flushTop = Boolean(recordType);
  }

  return (
    <React.Fragment>
      <QuerySync queryPath="filters" storePath="filters" />
      <Navbar textColor="var(--blue)">
        <Link to="/">
          <Logo />
        </Link>
      </Navbar>
      <Container>
        <div
          css={css(`
        transform: translateY(${flushTop ? '20px' : '20vh'});
        transition: transform 400ms;
      `)}
        >
          <h1 className="text-center">START HERE. SEE MORE.</h1>
          <RecordTypeTabs flushTop={flushTop} />
          {friendlyRecordType && (
            <h2 className="text-center" style={{ marginTop: 40 }}>
              I'm looking for {friendlyRecordType}
            </h2>
          )}
          {!isEvent && <FirstSection />}
          {isEvent && <EventSection />}
          {!isEvent && <SubcategorySection />}
          {!isEvent && <TagsSection />}
        </div>
        {(!category.isEmpty() || happensOn || !tags.isEmpty()) && <Counter />}
      </Container>
    </React.Fragment>
  );
};
export default Filters;
