/*@flow*/
import raf from 'lib/raf';

const w = window;
const doc = document;
/**
 * time for the smooth scrolling
 * @type {Number}
 */
const SCROLL_TIME = 50;

type Position = { top: number, left: number };
type Callback = Position => void;
let animationFrame;

/**
 * smoothScroll function by Alice Lietieur.
 * @see https://github.com/alicelieutier/smoothScroll
 * we use requestAnimationFrame to be called by the browser before every repaint
 * @param  {Object}   HTMLElement      the scroll context
 * @param  {Position}   dest      where to scroll to
 * @param  {Number}   duration scroll duration
 * @param  {Function} callback called when the scrolling is finished
 *
 * @returns  animationFrame | void
 */
const smoothScroll = (
  obj: HTMLElement,
  pos: $Shape<Position>,
  callback?: Callback
) => {
  let start = { top: obj.scrollTop, left: obj.scrollLeft };
  let clock = Date.now();
  const dest = { left: 0, top: 0, ...pos };

  let duration = getDuration(start, dest);

  // if currently animating, stop it. This prevents flickering.
  if (animationFrame) {
    // cross browser
    if (!cancelAnimationFrame(animationFrame)) {
      clearTimeout(animationFrame);
    }
  }

  // setup the stepping function
  const step = () => {
    // calculate timings
    let elapsed = Date.now() - clock;
    const top = position(start.top, dest.top, elapsed, duration);
    const left = position(start.left, dest.left, elapsed, duration);

    if (top === obj.scrollTop && left === obj.scrollLeft) {
      return;
    }

    obj.scrollTop = top;
    obj.scrollLeft = left;

    // check if we are over due
    if (elapsed > duration) {
      // is there a callback?
      if (typeof callback === 'function') {
        // stop execution and run the callback
        callback(dest);
      }
      // stop execution
      return;
    }
    // use a new animation frame
    animationFrame = raf(step);
  };

  // start the first step
  step();
};

/**
 * calc the duration of the animation proportional to the distance travelled.
 *
 * @param  {Position} start
 * @param  {Position} end
 * @return {Number}       scroll time in ms
 */
const getDuration = (startPos: Position, endPos: Position) => {
  let xDuration = 0;
  let yDuration = 0;

  if (startPos.left !== endPos.left) {
    xDuration = getAxisDuration(startPos.left, endPos.left);
  }

  if (startPos.top !== endPos.top) {
    yDuration = getAxisDuration(startPos.top, endPos.top);
  }

  return Math.max(xDuration, yDuration);
};

/**
 * calc the duration of the animation proportional to the distance travelled
 * for an axis.
 *
 * @param  {Number} start
 * @param  {Number} end
 * @return {Number}       scroll time in ms
 */
function getAxisDuration(start: number, end: number) {
  let distance = Math.abs(start - end);
  const clientWidth = getClientWidth();
  if (distance > clientWidth * 15) return 0;
  let procDist = (100 / Math.max(clientWidth, w.innerWidth || 1)) * distance;
  let duration = (100 / SCROLL_TIME) * procDist;
  return Math.max(SCROLL_TIME / 1.5, Math.min(duration, SCROLL_TIME));
}

// ease in out function thanks to:
// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
const easeInOutCubic = t => {
  return t * t * t;
};

/**
 * calculate the scroll position we should be in
 * @param  {Number} start    the start point of the scroll
 * @param  {Number} end      the end point of the scroll
 * @param  {Number} elapsed  the time elapsed from the beginning of the scroll
 * @param  {Number} duration the total duration of the scroll (default 500ms)
 * @return {Number}          the next position
 */
let position = function(
  start: number,
  end: number,
  elapsed: number,
  duration: number
) {
  if (elapsed > duration) {
    return end;
  }
  const inertia = easeInOutCubic(elapsed / duration);
  if (inertia > 0) {
    return start + (end - start) * inertia;
  }
  return end;
};

function getClientWidth() {
  return doc.documentElement ? doc.documentElement.clientWidth : 0;
}

export default smoothScroll;
