/*@flow*/
import React, { useEffect } from 'react';
import PresentedBy from 'comps/App/PresentedBy';
import RightArrow from 'comps/App/RightArrow';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Navbar from 'comps/App/Navbar';
import Logo from 'comps/App/Logo';

const Container = styled.div`
  display: grid;
  grid-template-rows: 30px 170px 170px 170px 1fr 100px;
  align-items: center;

  padding: 0px 30px;

  .section {
    display: grid;
    align-items: center;
    grid-template-columns: 90% 10%;
    grid-column-gap: 10px;

    height: 100%;
    color: white;

    & > div:nth-of-type(1) {
      border-bottom: solid 1px #ccc;
      height: inherit;
      display: grid;
      align-items: center;
    }

    & > div:nth-of-type(2) {
      text-align: center;
    }

    h1 {
      font-size: calc(2rem);
    }
    h4 {
      font-size: calc(1.5rem * 0.9);
    }
  }

  footer {
    text-align: center;
    a {
      color: white;
    }
  }
`;

export default () => {
  useEffect(() => {
    if (!window.twttr) return;
    window.twttr.widgets.createTimeline(
      {
        sourceType: 'url',
        url: 'https://twitter.com/LICPartnership',
      },
      document.getElementById('twitter-timeline'),
      { tweetLimit: 3 }
    );
  }, []);

  return (
    <>
      <Navbar fixed textColor="white" children={<span />} />
      <Logo style={{ paddingTop: 15 }} />
      <Container>
        <div className="separator" />
        <Link className="section" to="/events">
          <div>
            <div>
              <h1>What's Happening</h1>
              <h4>I'm looking for something to do</h4>
            </div>
          </div>
          <div>
            <RightArrow />
          </div>
        </Link>
        <Link className="section" to="/explore">
          <div>
            <div>
              <h1>Explore</h1>
              <h4>Show me everything nearby</h4>
            </div>
          </div>
          <div>
            <RightArrow />
          </div>
        </Link>
        <Link className="section" to="/filters?dest=/find">
          <div>
            <div>
              <h1>Find</h1>
              <h4>I need something specific</h4>
            </div>
          </div>
          <div>
            <RightArrow />
          </div>
        </Link>
        <div className="separator" />
        <PresentedBy />
      </Container>
      <div className="pl-3 pr-3">
        <div id="twitter-timeline" />
      </div>
    </>
  );
};
