/*@flow*/
import AttractionsQueryDef from 'queries/Attractions';
import useCachedQuery from 'lib/useCachedQuery';
import useStoreState from 'lib/useStoreState';
import * as I from 'immutable';
import type { DocumentNode } from 'graphql';
import { DEFAULT_FILTERS } from 'store/constants';

type ItemT = { id: string } & { [key: string]: any };

function mixVenueToAttractions(
  vs: ItemT[],
  items: ItemT[]
): (ItemT & { isVenue: boolean })[] {
  const venueIds = vs.map((v) => String(v.id));
  const isVenue = (id) => venueIds.includes(String(id));
  return items.map((v) => ({ ...v, isVenue: isVenue(v.id) }));
}

export default function useData(
  queryDef: DocumentNode = AttractionsQueryDef,
  key: string = 'attractions'
) {
  let { filters, companyId } = useStoreState({
    filters: 'filters',
    companyId: 'companyId',
  });

  // Add default categories to business filters if the
  // business section is empty and any_tags is present.
  if (
    filters.get('any_tags') &&
    filters.getIn(['businesses'], I.Map()).isEmpty()
  ) {
    filters = filters.updateIn(['businesses'], () => DEFAULT_FILTERS);
  }

  filters = filters.set('limit', 100);

  const variables = { filters, companyId };
  const o = { variables };
  const { data, loading: isLoading } = useCachedQuery(queryDef, o);
  const venues = I.getIn(data, ['company', 'venues'], []);
  let items = I.getIn(data, ['company', key], []);
  items = mixVenueToAttractions(venues, items);
  return [items, isLoading];
}
