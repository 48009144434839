/*@flow*/
import useStoreState from 'lib/useStoreState';
import * as I from 'immutable';
import { useEffect } from 'react';
import hash from 'lib/hash';
import { set } from 'store/ops';
import { DEFAULT_FILTERS } from 'store/constants';

export default function useDefaultFilters() {
  const { filters } = useStoreState({
    filters: 'filters',
  });

  // Use the default filters if the filters are empty on Explore pathway.
  useEffect(() => {
    filters &&
      !filters.get('businesses') &&
      set('filters.businesses', I.fromJS(DEFAULT_FILTERS));
  }, [hash(filters)]);
}
