/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export default (props: { children: ?React$Node }) => {
  if (!props.children) return null;
  return (
    <div
      css={css`
        background: var(--primary-gradient);
        padding: 40px 40px 20px 40px;
        color: white;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        position: relative;
      `}
    >
      {props.children}
      <div
        css={css(`
        margin-top: 20px;
        font-weight: lighter;
      `)}
      >
        LIC<b>PERKS</b>%
      </div>
    </div>
  );
};
