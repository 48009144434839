/*@flow*/
/** @jsx jsx */
import { jsx } from '@emotion/core';
import useTitle from 'lib/useTitle';
import PagesIndex from 'comps/Pages/Index';
import MapContext from '../Index/MapContext';
import useData from 'lib/useData';
import useIsochrone from './useIsochrone';
import useDefaultFilters from './useDefaultFilters';
import useTrackDecks from './useTrackDecks';
import Container from './Container';
import bizQueryDef from 'queries/Businesses';

const Component = () => {
  useTitle('LIC Local - Explore');
  useIsochrone();
  useDefaultFilters();
  const decks = useTrackDecks();
  const [items, isLoading] = useData(bizQueryDef, 'bizPages');

  return (
    <Container>
      <MapContext.Provider value={{ items, isLoading, decks }}>
        <PagesIndex />
      </MapContext.Provider>
    </Container>
  );
};

export default Component;
