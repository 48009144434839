/*@flow*/
/** @jsx jsx */
import { useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import ScrollToTop from 'comps/App/ScrollToTop';
import Router from 'comps/App/Router';
import Sidebar from 'comps/App/Sidebar';
import QuerySync from 'comps/App/QuerySync';
import DataSync from 'comps/App/DataSync';
import * as commands from 'store/commands';
import { jsx, css, Global } from '@emotion/core';
// ----- Pages -----
import Find from 'comps/Pages/Find';
import About from 'comps/Pages/About';
import Home from 'comps/Pages/Home';
import Events from 'comps/Pages/Events';
import Explore from 'comps/Pages/Explore';
import Filters from 'comps/Pages/Filters';
import Favorites from 'comps/Pages/Favorites';
import Feedback from 'comps/Pages/Feedback';
// $FlowFixMe
import { ReactComponent as Symbols } from 'images/symbols.svg';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import client from 'lib/apollo';
import store from 'store';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { StoreContext } from 'redux-react-hook';
import useRouter from 'lib/useRouter';

const NoMatch = () => {
  const { location } = useRouter();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </div>
  );
};

const url = (process.env: any).PUBLIC_URL;

const App = () => {
  const { location } = useRouter();
  useEffect(() => {
    commands.sendPageView({
      page_title: document.title,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return (
    <div
      className="App"
      css={css(`

      @font-face {
        font-family: Avenir;
        src: url('${url}/font/Avenir.ttc');
      }

      @font-face {
        font-family: Avenir;
        font-weight: normal;
        src: url('${url}/font/Avenir.ttc'),
             url('${url}/font/Avenir-Medium.woff2') format('woff2'),
             url('${url}/font/Avenir-Medium.woff') format('woff');
      }

      @font-face {
        font-family: Avenir-Light;
        font-weight: normal;
        src: url('${url}/font/Avenir-Light.woff2') format('woff2'),
             url('${url}/font/Avenir-Light.woff') format('woff');
      }

      @font-face {
        font-family: Avenir-Light-Oblique;
        font-weight: normal;
        src: url('${url}/font/Avenir-LightOblique.woff2') format('woff2'),
             url('${url}/font/Avenir-LightOblique.woff') format('woff');
      }

      @font-face {
        font-family: Avenir-Heavy;
        font-weight: bold;
        src: url('${url}/font/Avenir-Heavy.woff2') format('woff2'),
             url('${url}/font/Avenir-Heavy.woff') format('woff');
      }

      @font-face {
        font-family: myfont;
        font-weight: bold;
        src: url('${url}/font/Avenir.ttc'),
             url('${url}/font/Avenir-Heavy.woff2') format('woff2'),
             url('${url}/font/Avenir-Heavy.woff') format('woff');
      }

      font-family: Avenir, sans-serif;
  `)}
    >
      {process.env.__DEV__ && <QuerySync queryPath="ui" storePath="ui" />}
      <DataSync namespace="__lic_local__" storePath="Cache" />
      <Sidebar />
      <Switch>
        <Route exact path="/filters" component={Filters} />
        <Route path="/find" component={Find} />
        <Route path="/pages" component={Find} />
        <Route exact path="/explore" component={Explore} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/about" component={About} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/" component={Home} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
};

const Wrapper = ({ children }: { children: React$Node }) => {
  return (
    <StoreContext.Provider value={store}>
      <ReduxProvider store={store}>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
        </ApolloProvider>
      </ReduxProvider>
    </StoreContext.Provider>
  );
};

const Component = () => (
  <Wrapper>
    <Router>
      <ScrollToTop>
        <Global
          styles={css(`:root {
        --yellow: #f9a602;
        --green: #68b37d;
        --teal: #41a3aa;
        // Dark Blue
        --blue: #276782;
        --gray: #9b99a9;
        --dark-gray: #5f5d70;
        --primary: var(--blue);

        --primary-gradient: linear-gradient(45deg, #68b37d, #41a3aa);
        --secondary-gradient: linear-gradient(#41a3aa, #276782);

        --font-family-sans-serif: Avenir, sans-serif;
        --body-font-size: 1rem; // 16px
        --font-size-h-six: 0.9375rem; // 15px
        --font-size-h-five: 0.75rem; // 12px
        --font-size-h-four: 0.875rem; // 14px
        --font-size-h-three: 0.9375rem; // 14px
        --font-size-h-two: 1rem; // 16px
        --font-size-h-one: 1.375rem; // 22px

        // Colors
        --color-grocery: #3c91e6;
        --color-grocery-selected: #357ec8;
        --color-fitness: #06d6a0;
        --color-fitness-selected: #08b88a;
        --color-theater: #9381ff;
        --color-theater-selected: #7b6cd6;
        --color-restaurant: #da6c6f;
        --color-restaurant-selected: #be3837;
        --color-perks-program: #68b37d;
        --color-perks-program-selected: #68b37d;

        --color-events: #ffe74c;
      }
      h1 {
        font-size: var(--font-size-h-one);
      }
      h2 {
        font-size: var(--font-size-h-two);
      }
      h3 {
        font-size: var(--font-size-h-three);
      }
      h4 {
        font-size: var(--font-size-h-four);
      }
      h5 {
        font-size: var(--font-size-h-five);
      }
      h6 {
        font-size: var(--font-size-h-six);
      }

      body {
        // overscroll-behavior-y: contain;
      }

      html {
        height: 100vh;
      }

      body,
      #root,
      .App {
        height: inherit;
      }
      a,
      button {
        color: var(--blue);
        font-weight: bold;
        text-decoration: none !important;
      }
      .btn-link {
        color: var(--blue);
      }
      button {
        background: transparent;
        border: none;
        outline: 0 !important;
        box-shadow: none !important;
      }
`)}
        />
        <Route path="/" component={App} />
        <Symbols />
      </ScrollToTop>
    </Router>
  </Wrapper>
);

export default Component;
