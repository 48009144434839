/*@flow*/
import React from 'react';
import { LEVEL } from 'store/constants';
import styled from '@emotion/styled';
import Hammer from 'lib/Hammer';
import sendTouchEvent from 'lib/sendTouchEvent';
import * as commands from 'store/commands';
import getItemWidth from './getItemWidth';
import { type ItemT } from '../types';

const getIndexOfFirstVisibleItem = () => {
  const scrollLeft = getScrollLeft();
  // Find its outer width.
  const width = getItemWidth();
  // Determine index of element in array.
  const ix = scrollLeft / width;
  return Math.round(ix);
};

const getVirtualList = () => document.querySelector('.scroller .virtual-list');

const getScrollLeft = () => {
  const node = getVirtualList();
  return node ? node.scrollLeft : 0;
};

const showOnMap = item => {
  // Dispatch command to focus on item.
  const { id, lonlat } = item || {};
  id && lonlat && commands.showDetails({ id, lonlat });
};

const Enhancer = Component => (props: {
  hide?: boolean,
  items: ItemT[],
  setScrollToIndex: Function,
}) => {
  if (props.hide) return null;
  const { items } = props;
  return (
    <Hammer
      direction="DIRECTION_HORIZONTAL"
      onSwipe={e => {
        e.preventDefault();
        e.srcEvent.stopPropagation();

        const ix = getIndexOfFirstVisibleItem();
        const getNextIx = () => {
          let nix = ix + (e.deltaX < 0 ? 1 : -1);
          // If the next index is negative, return the previous index.
          if (nix < 0) return ix;
          // If the next index is out of bounds, return the previous index.
          if (nix >= items.length) return ix;
          // return positive integer, smaller than items.length
          return nix;
        };
        const nix = getNextIx();

        // Show the item on map.
        showOnMap(items[nix]);

        setTimeout(() => props.setScrollToIndex(nix), 20);
      }}
      onTap={e => {
        const { x, y } = e.center;
        e.preventDefault();
        e.srcEvent.stopPropagation();
        const overlay = document.elementFromPoint(x, y);
        if (!overlay) return;
        overlay.style.pointerEvents = 'none';
        const target = document.elementFromPoint(x, y);
        setTimeout(() => (overlay.style.pointerEvents = 'auto'), 20);
        if (!target) return;
        sendTouchEvent({ element: target, x, y, type: 'touchend' });
      }}
    >
      <Component />
    </Hammer>
  );
};

const Component = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${LEVEL.SCROLLER};
  overscroll-behavior: none;
`;

export default Enhancer(Component);
