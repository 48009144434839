/*@flow*/
import React from 'react';
import HugeOverlay from 'comps/App/Sidebar/HugeOverlay';
import styled from '@emotion/styled';
import { type HOC } from 'recompose';
import { con } from 'store/ops';
import Logo from 'comps/App/Logo';
import PresentedBy from 'comps/App/PresentedBy';
import { Link } from 'react-router-dom';
import * as commands from 'store/commands';
import RightArrow from 'comps/App/RightArrow';

const Content = styled.div(`
  display: grid;
  grid-template-columns: 15% 1fr;
  height: 55vh;
  margin: 80px 0 20px;

  & > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 25%);
    grid-row-gap: 5px;

    & > a {
      display: grid;
      grid-template-columns: 1fr 20%;
      align-items: center;
      justify-content: center;

      span {
        text-align: center;
      }

      font-weight: 300;
      color: #fff;
      transition: color 0.2s;
      font-size: 24px;
      text-align: left;

      & > div {
        border-bottom: solid 2px white;
        height: 100%;

        display: grid;
        align-items: center;

      }
    }
  }
`);

const Header = styled.div(`
  text-align: center;
  width: 100%;
  padding-top: 16px;
`);

const Footer = styled.div(`
  padding-top: 40px;
  text-align: center;

  a {
    color: #fff;
  }
`);

const CloseButton = styled.button(`
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
`);

type EnhancedPropsT = { isOpen: boolean };
const Enhancer: HOC<EnhancedPropsT, *> = con({
  isOpen: 'ui.Sidebar.isOpen',
});

const Component = (props: EnhancedPropsT) => {
  return (
    <HugeOverlay
      bgColor="var(--primary-gradient)"
      offsetTop="30%"
      header={
        <Header>
          <Logo />
          <CloseButton onClick={() => commands.closeSidebar()}>
            <i className="fa fa-times fa-2x" />
          </CloseButton>
        </Header>
      }
      isOpen={props.isOpen}
    >
      <React.Fragment>
        <Content>
          <div />
          <div>
            <Link onClick={() => commands.closeSidebar()} to="/">
              <div>Home</div>
              <RightArrow />
            </Link>
            <Link onClick={() => commands.closeSidebar()} to="/about">
              <div>LIC Local</div>
              <RightArrow />
            </Link>
            <Link
              onClick={() => commands.closeSidebar()}
              to={{ pathname: '/feedback' }}
            >
              <div>Feedback</div>
              <RightArrow />
            </Link>
            <Link
              onClick={() => commands.closeSidebar()}
              to={{ pathname: '/favorites' }}
            >
              <div>Favorites</div>
              <RightArrow />
            </Link>
          </div>
          <div />
        </Content>
        <Footer>
          <PresentedBy />
        </Footer>
      </React.Fragment>
    </HugeOverlay>
  );
};

export default Enhancer(Component);
