/*@flow*/
import React, { useEffect, useState, useContext } from 'react';
import Atlas from 'comps/App/Atlas';
import * as commands from 'store/commands';
import { CENTER_OF_LIC, LEVEL } from 'store/constants';
import styled from '@emotion/styled';
import hash from 'lib/hash';
import { type LonLatT } from 'store/types';
import { CategoryColorMap } from 'comps/Pages/Filters/categoryToColor';
import * as I from 'immutable';
import useStoreState from 'lib/useStoreState';
import useRouter from 'lib/useRouter';
import MapContext from './MapContext';
import mkPoints from './mkPoints';

const filter = (arr, fn) => arr.filter(v => fn(v));
const filterSelected = points => filter(points, v => v.properties.inFocus);

function usePoints(focusId, attractions) {
  const [points, setPoints] = useState([]);
  useEffect(() => {
    const v = mkPoints(focusId, attractions);
    setPoints(() => v);
  }, [focusId, hash(attractions)]);

  return points;
}

const mkFeatures = arr =>
  arr.map(v => {
    return {
      geometry: {
        type: 'Point',
        coordinates: v.lonlat,
      },
      properties: {
        icon: 'marker-15',
        inFocus: false,
        ...(v.properties || {}),
        id: v.id,
      },
    };
  });

const markerEvents = {
  click: e => {
    const { id: _id } = e.features[0].properties;
    const lonlat = e.features[0].geometry.coordinates;
    const item = { id: _id, lonlat };
    commands.clickMarker(item);
  },
  mouseenter: e => {
    e.target.getCanvas().style.cursor = 'pointer';
  },
  mouseleave: e => {
    e.target.getCanvas().style.cursor = '';
  },
};

const mkGeoJSONMarkers = (id, data) => ({
  id,
  type: 'geojson',
  data,
  layout: {
    'icon-image': '{icon}',
    'icon-size': 1,
    'icon-allow-overlap': true,
    'icon-offset': { type: 'identity', property: 'icon-offset' },
  },
  events: markerEvents,
});

const mkCircleMarkers = (id, source) => ({
  id,
  type: 'layer',
  config: {
    id,
    type: 'circle',
    source,
    paint: {
      // make circles larger as the user zooms from z12 to z22
      'circle-radius': {
        base: 2.7,
        stops: [[12, 4], [22, 180]],
      },
      // color circles by ethnicity, using a match expression
      // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
      'circle-color': [
        'match',
        ['get', 'category'],
        ...Object.entries(CategoryColorMap).reduce(
          (s, arr) => [...s, ...arr],
          []
        ),
        /* other */ '#68b37d',
      ],
      'circle-stroke-color': 'transparent',
      'circle-stroke-width': 10,
    },
  },
  placeBefore: 'selected-points',
  events: markerEvents,
});

const mkGeoJSONSource = points => {
  return {
    id: 'points-source',
    type: 'source',
    config: {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: points.map(v => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: v.lonlat,
            },
            properties: {
              id: v.id,
              category: v.properties.category,
            },
          };
        }),
      },
    },
  };
};

// const metersToPixelsAtMaxZoom = (meters, latitude) =>
//   meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

const Container = styled.div(
  props => `
  position: relative;
  height: ${props.mapHeight || '100vh'};
  z-index: ${LEVEL.MAP};

  .mapboxgl-ctrl-top-right {
    position: relative;
    top: 75px;
    right: 2px;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group {
    button { background-color: white !important; }
    border-radius: 25px;
    padding: 4px;
  }
`
);

function mkUserLocationMarker() {
  return {
    id: 'userLocationMarker',
    type: 'userLocationMarker',
    events: {
      ready: () => {
        const selector = '.mapboxgl-user-location-dot svg';
        const nodes = document.querySelectorAll(selector);
        if (nodes) {
          nodes.forEach(node => node.setAttribute('fill', 'white'));
        }
      },
    },
  };
}

type Point = {
  id: ID,
  lonlat: LonLatT,
  properties: { category: any, inFocus: boolean, icon: string, title: ?string },
};

type PropsT = {|
  children?: React$Node,
  height?: string | number,
|};

const IndexMap = (props: PropsT) => {
  let { focusId, center, resize, radiusCenter } = useStoreState({
    focusId: 'Focus.id',
    center: 'Map.center',
    resize: 'Map.didResizeHash',
    radiusCenter: 'filters.within.theGeom',
  });
  const { items, isLoading, decks: newDecks = [] } = useContext(MapContext);
  const points: Point[] = usePoints(focusId, items);

  const [config, setConfig] = useState({
    style: 'mapbox://styles/citiesense/cjri4diol131g2smgh7eft7gg',
    center: center ? center.toJS() : CENTER_OF_LIC,
    zoom: 14,
    minZoom: 14,
    attributionControl: false,
    resize,
    events: {
      load: e => {
        commands.setMapCenter(e.target.getCenter());
        commands.saveMapBbox(e.target);
      },
      moveend: e => {
        commands.saveMapBbox(e.target);
      },
    },
  });

  useEffect(() => {
    setConfig(v => ({ ...v, resize }));
  }, [resize]);

  useEffect(() => {
    center && setConfig(v => ({ ...v, center: center.toJS() }));
  }, [hash(center)]);

  const [decks, setDecks] = useState([
    {
      id: 'navigation',
      type: 'navigation',
      position: 'top-right',
      showZoom: false,
    },
  ]);

  useEffect(() => {
    setDecks(v => v.concat(newDecks));
  }, [hash(newDecks)]);

  useEffect(() => {
    if (!radiusCenter) return;
    const c = radiusCenter.toJS();
    setConfig(v => ({ ...v, center: c }));
  }, [radiusCenter]);

  useEffect(() => {
    if (points.length === 0) {
      setDecks([]);
    } else {
      setDecks(v => {
        return I.List(v)
          .filterNot(v2 =>
            ['selected-points', 'points', 'points-source', 'userLocationMarker']
              .concat(points.map(v3 => v3.id))
              .includes(v2.id)
          )
          .concat([
            mkGeoJSONMarkers(
              'selected-points',
              mkFeatures(filterSelected(points))
            ),
            mkGeoJSONSource(points),
            mkCircleMarkers('points', 'points-source'),
            mkUserLocationMarker(),
          ])
          .toArray();
      });
    }
  }, [hash(points)]);

  const { location } = useRouter();
  useEffect(() => {
    commands.mapDidResize();
  }, [location.pathname]);

  return (
    <Container className="map-container" mapHeight={props.height}>
      <Atlas decks={decks} config={config} loading={Boolean(isLoading)} />
      {props.children}
    </Container>
  );
};

export default IndexMap;
