/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
// $FlowFixMe
import { ReactComponent as Compass } from './compass.svg';
import PresentedBy from 'comps/App/PresentedBy';
import instruction from './qr_code.png';
import scanMe from './scan_me2.png';

const Container = styled.div`
  * {
    color: white;
  }
  max-width: 540px;
  margin: 0 auto;

  padding: 30px;
  text-align: center;

  display: grid;
  align-items: center;
  grid-template-rows: 30px auto 40px;
  grid-row-gap: 20px;
`;

const Title = styled.div`
  font-family: Avenir-Light;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`;

const Light = styled.span`
  font-family: Avenir-Light;
  font-weight: 100;
`;

export default () => (
  <Container>
    <Title>
      <Light>WELCOME TO</Light>{' '}
      <b
        css={css(`
        font-family: Avenir-Heavy;
        font-weight: bold;
      `)}
      >
        LIC
      </b>{' '}
      <Light>LOCAL</Light>
      <br />
      <small>
        <Light>(OUR WEBSITE WORKS BEST ON MOBILE DEVICES)</Light>
      </small>
    </Title>
    <div>
      <div>
        <div
          css={css(`
          position: relative;
        `)}
        >
          <Compass />
          <div
            css={css(`
            position: absolute;
            left: 0; right: 0; bottom: 0; top: 0;
            text-align: center;
            padding-top: 115px;

            * { color: var(--blue); }
            font-size: 1.7rem;
          `)}
          >
            <img src={scanMe} alt="Scan me" style={{ width: 80 }} />
          </div>
        </div>
        <br />
        <img src={instruction} alt="instruction" style={{ width: 80 }} />
        <p>
          Use your phone’s camera to scan the code or go to liclocal.nyc on your
          phone's browser.
        </p>
      </div>
    </div>
    <div>
      <a
        css={css(`
        margin-bottom: 1rem;
        display: inline-block;
      `)}
        href="https://licqns.com/licspringsback"
      >
        Visit the LIC Springs Back site here
      </a>
      <PresentedBy />
    </div>
  </Container>
);
