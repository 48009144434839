/*@flow*/
/** @jsx jsx */
import useTitle from 'lib/useTitle';
import NonEmptyArray from 'lib/NonEmptyArray';
import EmptyObject from 'lib/EmptyObject';
import styled from '@emotion/styled';
import Navbar from 'comps/App/Navbar';
import Perks from 'comps/App/Perks';
import Gallery from './Gallery';
import AttractionDef from 'queries/Attraction';
import { jsx, css } from '@emotion/core';
import mkFileStackUrl from 'lib/mkFileStackUrl';
import { LEVEL } from 'store/constants';
import ButtonRow from './ButtonRow';
import Events from './Events';
import Details from './Details';
import Similar from './Similar';
import ShowMap from './Map';
import StartEnd from './StartEnd';
import TruncatedText from 'comps/App/TruncatedText';
import * as I from 'immutable';
import useStoreState from 'lib/useStoreState';
import Toaster from 'comps/App/Toaster';
import useRouter from 'lib/useRouter';
import useCachedQuery from 'lib/useCachedQuery';
import { type AttractionQuery_company_attraction } from 'queries/__generated__/AttractionQuery';

const Title = styled.h1(`
  text-align: center;
  margin: 0px 0 10px;
`);

const Subcategories = styled.h6(`
  text-align: center;
  margin: 0px 0 10px;
`);

const Padded = styled.div(`
  padding: 15px 10px 0;
`);

const GridRow = styled.div();

const Container = styled.div(
  `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  z-index: ${LEVEL.SHOW_PAGE};
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  display: grid;
  grid-template-rows: 62px 120px 1fr 83px;
  grid-template-columns: 100vw;

  .navbar-brand {
    margin-top: 1px;
  }

  .description-container * {
    font-family: Avenir, sans-serif !important;
  }
`
);

const ShowPage = () => {
  const { match, history } = useRouter();
  const { companyId } = useStoreState({
    companyId: 'companyId',
    filters: 'filters',
  });
  const id = I.getIn(match, ['params', 'id'], null);
  const variables = { companyId, id };
  const { data, loading: isLoading } = useCachedQuery(AttractionDef, {
    variables,
  });

  useTitle(ctx => {
    const { name } = ctx.value || {};
    return ['LIC Local', name].filter(Boolean).join(' - ');
  });

  const v: AttractionQuery_company_attraction =
    I.getIn(data, ['company', 'attraction'], {}) || {};

  const images = NonEmptyArray.is(v.photos)
    ? (v.photos || []).map(src => mkFileStackUrl(src, 480, 480))
    : [];

  if (EmptyObject.is(v))
    return (
      <Container className="attractions show" isOpen={match.isExact}>
        <div
          css={css(`height: 80vh; width: 100vw;`)}
          className="d-flex justify-content-center align-items-center"
        >
          <i className="fa fa-spinner fa-spin" />
        </div>
      </Container>
    );

  return (
    <Container className="attractions show" isOpen={match.isExact}>
      <Navbar
        sticky
        textColor="var(--blue)"
        backBtn={
          <button
            onClick={() => {
              history.goBack();
            }}
            css={{ padding: '2px 10px' }}
          >
            <i className="fa fa-angle-left" />
          </button>
        }
      >
        {v.category}
      </Navbar>
      <ShowMap
        center={v.lonlat}
        height="120px"
        points={[{ id: v.id, lonlat: v.lonlat }]}
        isLoading={isLoading}
      />
      <GridRow>
        <Padded>
          <Title>{v.name}</Title>
          <StartEnd {...v} />
          <Gallery images={images} />
          {v.subcategories && (
            <Subcategories>{v.subcategories.join(', ')}</Subcategories>
          )}
          <TruncatedText
            text={v.description}
            limit={100}
            html
            className="pl-3 pr-3 description-container"
          />
          <ButtonRow
            id={v.id}
            destination={[...v.lonlat].reverse().join(',')}
          />
          <Details
            days={v.hours || []}
            phone={v.phone}
            address={v.address}
            url={v.url}
            socialMedia={v.socialMedia || {}}
          />
          <Events events={v.events || []} />
        </Padded>
        <Perks>{v.perks}</Perks>
        <Similar
          key={v.id}
          id={v.id}
          category={v.category}
          subcategories={v.subcategories}
        />
        <Toaster />
      </GridRow>
    </Container>
  );
};

export default ShowPage;
