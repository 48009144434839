/*@flow*/
import React from 'react';
import useTitle from 'lib/useTitle';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import licImg1 from './pngs/lic_queens.png';
import licImg2 from './pngs/lic_queens_2.png';
import licImg3 from './pngs/lic_queens_3.png';
import PresentedBy from 'comps/App/PresentedBy';
import useRouter from 'lib/useRouter';

const Page = styled.div({
  width: '100vw',
  overflow: 'hidden',
});

const Container = styled.div(`
  padding: 30px 40px;
`);

const About = () => {
  useTitle('LIC Local - About');
  const { history } = useRouter();
  const goBack = () => {
    history.goBack();
  };

  return (
    <Page>
      <Navbar
        textColor="var(--blue)"
        backBtn={
          <button onClick={goBack} css={{ padding: '5px 10px' }}>
            <i className="fa fa-angle-left" />
          </button>
        }
      >
        <Link style={{ marginTop: 2, display: 'inline-block' }} to="/">
          LIC Local
        </Link>
      </Navbar>
      <img style={{ width: '100%' }} src={licImg1} alt="lic" />
      <Container>
        <h1>What is LIC Local?</h1>
        <PresentedBy withColor />
        <p style={{ marginTop: 10 }}>
          LIC Local is a smart signage system and mobile site that helps you
          discover all that Long Island City has to offer, from unique
          neighborhood businesses to community events.
        </p>
      </Container>
      <img style={{ width: '100%' }} src={licImg2} alt="lic" />
      <Container>
        <h2>How to use LIC Local</h2>
        <ol>
          <li>
            Using your smartphone’s camera, scan a QR code on one of the LIC
            Local signs around the neighborhood.
          </li>
          <li>
            Based on your location,{' '}
            <Link to="/events">search for events to check out</Link>,{' '}
            <Link to="/filters?dest=%2Ffind">find a specific business</Link>, or{' '}
            <Link to="/explore">browse a map of everything</Link> available
            within walking distance.
          </li>
          <li>Learn where special deals are available to LIC Perks members!</li>
        </ol>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img style={{ width: '100%' }} src={licImg3} alt="lic" />
        </div>
        <h3>Search Local</h3>
        <p>
          LIC Local was created and is managed by the Long Island City
          Partnership, the neighborhood development organization for Long Island
          City that runs the LIC Business Improvement District and Industrial
          Business Zone. By using LIC Local you’ll be supporting your local
          retail shops, network of small businesses, cultural destinations and
          landmarks, and neighborhood events.
        </p>
        <p>
          LIC Local is currently being offered as a pilot program. If you have
          ideas for how to make the program even stronger,{' '}
          <Link to="/feedback">
            please click here to send us your feedback!
          </Link>
        </p>
        <p>
          Want to have your business or event listed on LIC Local?{' '}
          <a
            href="https://longislandcityqueens.com/experience-lic/events/submit/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to get in touch.
          </a>
        </p>
        <p>
          This program is funded in part by the NYC Department of Small Business
          Services. Additional funding and support provided by Empire State
          Development, The New York Community Trust, Queens Borough President
          Melinda Katz, NYC Council Member Jimmy Van Bramer, and LIC
          Partnership.
        </p>
        <p>
          Thank you to Boyce Technologies for fabrication of the LIC Local
          signage.
        </p>
        <p>
          We also want to thank members of the LIC Community Streetscape and
          Arts Initiative Committee, the Technical Advisory Committee, and LIC
          Partnership’s Board Streetscape and Arts Committee for their input,
          feedback, and support in guiding the creation of this program.
        </p>
      </Container>
    </Page>
  );
};

export default About;
