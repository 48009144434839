/*@flow*/
import React from 'react';
import whiteLogo from './logo1.png';
import colorLogo from './logo2.png';

export default (props: { withColor?: boolean }) => (
  <footer>
    <a
      href="https://longislandcityqueens.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span>
        Presented By{' '}
        <img src={props.withColor ? colorLogo : whiteLogo} alt="licp" />
      </span>
    </a>
  </footer>
);
