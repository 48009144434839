/*@flow*/
import React, { useState, useEffect, createRef } from 'react';
import styled from '@emotion/styled';
import * as commands from 'store/commands';
import cx from 'classnames';
import { LEVEL } from 'store/constants';
import { SEARCH_HEIGHT } from 'store/constants';
import useStoreState from 'lib/useStoreState';

const Form = styled.form(`
  border-top: solid 1px #efefef;
  border-bottom: solid 1px #efefef;
  margin-top: 0px;
  margin-bottom: 3px;
  height: ${SEARCH_HEIGHT.toString()};
`);

const Input = styled.input`
  :focus {
    outline: none;
  }
  line-height: 120%;
  font-size: 0.875rem;
  height: inherit;
  padding: 0.175rem 0.25rem;
  border: none;

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  z-index: ${LEVEL.DRAWER};
  background: white;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 32.5px 32.5px;
  padding: 3px 5px;
  & > span {
    display: grid;
    justify-items: center;
    align-items: center;
  }
`;

const SearchIcon = styled.span(props => ({
  opacity: props.inert ? 0.2 : 1,
}));

const FilterIcon = styled.span('color: var(--blue);');

const Backdrop = styled.div(
  props => `
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: ${LEVEL.DRAWER - 1};
  display: ${props.show ? 'block' : 'none'};
`
);

type InputPropsT = {
  onShowFilters: Function,
};

const Search = (props: InputPropsT) => {
  const { q } = useStoreState({
    q: 'filters.q',
  });
  const [hasTextValue, setHasTextValue] = useState(false);
  const [focused, setFocused] = useState(false);
  const [keywordQuery, setQuery] = useState(q || '');
  const ref = createRef();

  function runSearch() {
    commands.search(keywordQuery);
  }

  useEffect(() => {
    setHasTextValue(Boolean(q));
  }, [q]);

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        ref.current && commands.search(ref.current.value);
        setFocused(false);
        setHasTextValue(ref.current && Boolean(ref.current.value));
        ref.current && ref.current.blur();
      }}
      action="."
    >
      {focused && (
        <Backdrop
          show={focused}
          onClick={() => {
            ref.current && commands.search(ref.current.value);
          }}
        />
      )}
      <InputGroup>
        <Input
          type="search"
          ref={ref}
          placeholder="I'm looking for"
          value={keywordQuery || ''}
          onChange={e => {
            setQuery(e.target.value);
          }}
          onFocus={() => {
            commands.upDrawer();
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
            runSearch();
          }}
        />
        <SearchIcon
          className={cx('fa', {
            'fa-search': !hasTextValue,
            'fa-times': hasTextValue,
          })}
          inert={!hasTextValue}
          onClick={() => {
            if (hasTextValue) {
              if (ref.current) ref.current.value = '';
              commands.search('');
              setQuery('');
              setHasTextValue(false);
            }
          }}
        />
        <FilterIcon
          className={cx('fa', {
            'fa-sliders-h': true,
          })}
          onClick={props.onShowFilters}
        />
      </InputGroup>
    </Form>
  );
};

export default Search;
