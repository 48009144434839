/*@flow*/
import type { Map } from 'mapbox-gl';
import { set, state, truthy } from 'store/ops';
import * as I from 'immutable';
import qs from 'qs';
import { type Mode } from 'mapbox-isochrone';
import iso from 'lib/iso';
import { type Location } from 'react-router-dom';

export const showDetails = (
  props: $Shape<{ id: ID, lonlat: number[] }> = {}
) => {
  set('Focus.id', props.id);
  const [lng, lat] = props.lonlat;
  setMapCenter({ lng, lat });
};

export const hideDetails = () => {
  set('Focus.id', null);
};

export const setSectionName = (name: string) => {
  set('Page.sectionName', name);
};

export const showToaster = () => {
  set('Toaster.isUp', true);
  setTimeout(() => set('Toaster.isUp', false), 2400);
};

export const toggleFavorite = (id: string) => {
  const favs = state('Cache.Favorites', I.List());
  const isId = v => v === id;
  const isChecked = favs.includes(String(id));
  if (!isChecked) {
    showToaster();
  }

  sendBookmarkEvent(id);

  const setter = () => {
    return isChecked ? favs.filterNot(isId) : favs.push(String(id));
  };
  set('Cache.Favorites', setter);
};

export const clickMarker = (
  props: $Shape<{ id: ID, lonlat: number[] }> = {}
) => {
  set('Map.clickedItem', I.fromJS(props));
  showDetails(props);
  upDrawer();
};

export const deleteClickedItem = () => {
  set('Map', v => v && v.delete('clickedItem'));
};

export const downMap = () => {
  set('Map.isDown', true);
};

export const upMap = () => {
  set('Map.isDown', false);
};

export const setResultCount = (value: number) => {
  set('Counter.value', value || 0);
};

export const upDrawer = () => {
  set('Drawer.isDown', false);
  upMap();
};

export const downDrawer = () => {
  set('Drawer.isDown', true);
  downMap();
};

export const toggleSidebar = () => {
  if (truthy('ui.Sidebar.isOpen')()) {
    closeSidebar();
  } else {
    openSidebar();
  }
};

export const openSidebar = () => {
  set('ui.Sidebar.isOpen', true);
  set('ui.Sidebar.status', 'OPEN');
};

export const closeSidebar = () => {
  set('ui.Sidebar.isOpen', false);
  set('ui.Sidebar.status', 'CLOSING');
  setTimeout(() => set('ui.Sidebar.status', 'CLOSED'), 10);
};

export const mapDidResize = () => {
  set('Map.didResizeHash', Math.random());
};

//  -------- <Filters>
export const setFilters = (value: Object) => {
  set('filters', I.fromJS(value));
};

export const clearLocationFilters = () => {
  // Delete 'within', 'bbox' filters, which relate to location.
  set('filters', v =>
    (v || I.Map())
      .delete('within')
      .delete('bbox')
      .delete('limit')
  );
};

export const clearFilters = () => {
  set('filters', I.Map({ limit: 0 }));
};

export const setEventPageFiltersOnLoad = () => {
  if (!state('filters.events.happensOn')) {
    set(
      'filters',
      I.fromJS({ type: ['Event'], events: { happensOn: 'This Month' } })
    );
  }
};

export const setFilterType = (value: string) => {
  set('filters.type', value);
};

export const setHappensOn = (value: string) => {
  set('filters.events.happensOn', value);
};

const projectFilters = (value, target, aux = false) => {
  // Resource-to-filter-key map.
  const m = { Tenant: 'businesses', Event: 'events' };

  const applyValueToTarget = item => {
    const filterKey = m[item];
    const valueImm: any = I.fromJS(value);
    if (valueImm.isEmpty()) {
      const updateFilterKeyValues = v => {
        const v2: I.Map<*, *> = v || I.Map();
        if (target === 'subcategories' && aux) {
          const updateTarget = v3 => {
            const list = v3 || I.List();
            // Keep 'Perks Program' in subcategories.
            return list.filter(v4 => v4 === 'Perks Program');
          };
          const v5 = v2.update(target, updateTarget);
          if (v5.isEmpty()) return v2.delete(target);
          return v5;
        }
        return v2.delete(target);
      };
      set(`filters.${filterKey}`, updateFilterKeyValues);
    } else {
      const keyPath = `filters.${filterKey}.${target}`;
      set(keyPath, valueImm);
    }
  };
  const defaultResources = ['Tenant'];
  const resources = state('filters.type', defaultResources);
  resources.forEach(applyValueToTarget);
};

export const setRecordTypes = (value: string) => {
  const m = { Place: 'Tenant', Event: 'Event' };
  // Clear everything and add 'filters.type'
  set('filters', v =>
    I.Map(v || {})
      .clear()
      .set('type', I.List([m[value]]))
  );
};

export const setCategories = (value: string[]) => {
  // Clear sub-selections
  projectFilters([], 'subcategories', true);
  projectFilters([], 'tags', true);
  // Finally set selection.
  projectFilters(value, 'category');
};

export const setSubcategories = (value: string[]) => {
  // Clear sub-selections
  projectFilters([], 'tags', true);
  // Finally set selection.
  projectFilters(value, 'subcategories');
};

export const setTags = (value: string[]) => {
  projectFilters(value, 'tags');
};

export const setBboxFilter = (map: Map) => {
  set(
    'filters.bbox',
    map
      .getBounds()
      .toArray()
      .join(',')
  );
};

//  -------- </Filters>

export const setMapCenter = ({ lng, lat }: { lng: number, lat: number }) => {
  const lngLat = [lng, lat];
  set('Map.center', I.fromJS(lngLat));
};

export const showIsochroneForTransportationMode = (mode: Mode) => {
  const theGeom = state('Map.center');
  const token = state('Map.token');

  const visualize = output => {
    function transformFeatures(
      geom: GeoJSON$FeatureCollection<
        GeoJSON$Polygon,
        {
          time: number,
          minutes: number,
          quantized: number,
          area: number,
        }
      >
    ) {
      const getQuantized = seconds =>
        seconds % 600 === 0
          ? 3600
          : seconds % 300 === 0
          ? 1800
          : seconds % 300 === 0
          ? 900
          : 1;

      geom.features = geom.features
        .map(ft => {
          let modified = ft;
          const seconds = ft.properties.time;
          modified.properties.minutes = seconds / 60;
          modified.properties.quantized = getQuantized(seconds);
          modified.properties.area = window.ruler.area(
            modified.geometry.coordinates
          );
          return modified;
        })
        .reverse();

      return geom;
    }

    const geom = transformFeatures(output);
    set('Map.isochrone.hulls.data', geom);
  };

  const params = {
    token,
    mode,
    threshold: 3600,
    resolution: 1.5,
    batchSize: 400,
    direction: 'divergent',
  };

  if (theGeom) {
    if (token) {
      iso(theGeom.toJS(), params)
        .then(visualize)
        .catch(window.onerror);
    } else {
      window.onerror('Token missing.');
    }
  } else {
    setTimeout(() => showIsochroneForTransportationMode(mode), 100);
  }
};

export const saveMapBbox = (map: Map) => {
  const bbox = map.getBounds().toArray();
  set('Map.bbox', I.fromJS(bbox));
};

export const scrollTop = (offset?: number) => {
  document.body && window.scrollTo(0, offset || 0);
};

export const setTextSearchFilter = (q: string) => {
  set('filters', v => (q ? v.set('q', q) : v.delete('q')));
  scrollTop();
};

export const search = (q: string) => {
  setTextSearchFilter(q);
};

export const setFiltersFromParam = (loc: Location) => {
  const search = loc.search.slice(1);
  const params = qs.parse(search);
  let paramsIm: any = I.fromJS(params);
  const filters = paramsIm.get('filters', I.Map());

  if (filters.isEmpty()) return;
  set('filters', filters);
};

export const setFilterFromMapBounds = () => {
  const bbox = state('Map.bbox', I.List());
  const bboxInFilter = state('filters.bbox', '');
  const bboxChanged = bbox.toJS().join(',') !== bboxInFilter;

  if (bboxChanged && !bbox.isEmpty()) {
    set('filters', v =>
      (v || I.Map()).delete('limit').set('bbox', bbox.toJS().join(','))
    );
  }
};

export const clearBoundsFilter = () => {
  set('filters', v => (v || I.Map()).delete('bbox'));
};

export const clearIsochroneData = () => {
  set('Map.isochrone.hulls', v => (v || I.Map()).delete('data'));
};

export const sendBookmarkEvent = (id: string) => {
  // Enable in production mode only.
  if (!process.env.__PROD__) return;

  const value = `${process.env.REACT_APP_HOST || ''}/pages/${id}`;

  const o = {
    event_category: 'Bookmarks',
    event_label: 'Place URL',
    value,
  };
  window.gtag('event', 'bookmark', o);
};

export const sendPageView = (o: {
  page_title?: string,
  page_path?: string,
  page_location?: string,
}) => {
  // Enable in production mode only.
  if (!process.env.__PROD__) return;
  window.gtag('config', process.env.REACT_APP_GA_ID, o);
};
