/*@flow*/
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';

function getLocalUri() {
  const DEV = process.env.__DEV__;
  const DOMAIN = DEV ? 'citiesense' : 'app.ginkgo';
  const TLD = DEV ? 'dev' : 'city';
  return `https://${DOMAIN}.${TLD}/graphql`;
}

function getNetworkUri() {
  return `http://${window.location.hostname}:9003/graphql`;
}

function isOnNetwork() {
  return window.location.port === '9002';
}

export function mkClient() {
  const uri = isOnNetwork() ? getNetworkUri() : getLocalUri();
  const httpLink = new HttpLink({ uri });

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      credentials: 'omit',
      mode: 'no-cors',
    });
    return forward(operation);
  });

  return new ApolloClient<*>({
    link: concat(middlewareLink, httpLink),
    cache: new InMemoryCache(),
  });
}

export default mkClient();
