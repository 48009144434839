/*@flow*/
/** @jsx jsx */
import React from 'react';
import useTitle from 'lib/useTitle';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import { con } from 'store/ops';
import * as commands from 'store/commands';
import * as I from 'immutable';
import { type List } from 'immutable';
import FavoritesQueryDef from 'queries/Favorites';
import type {
  FavoritesQuery,
  FavoritesQuery_company_attractions,
} from 'queries/__generated__/FavoritesQuery';
import { useQuery } from 'react-apollo-hooks';
import Loading from 'comps/App/Loading';
import nested from 'lib/nested';
import { useState } from 'react';
import NonEmptyObject from 'lib/NonEmptyObject';

type AttractionT = FavoritesQuery_company_attractions;

const Page = styled.div({
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
});

const Container = styled.div(`
  padding: 30px 40px;
`);

const EmptySpace = () => (
  <div
    style={{
      textAlign: 'center',
      minHeight: 150,
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center',
    }}
  >
    You have not favorited any places.
    <p>
      Go to <Link to="/explore">Explore</Link> to find a place now.
    </p>
  </div>
);

const ItemRow = ({ id, name }) => (
  <div
    css={css(`
      display: grid;
      grid-template-columns: 50px 1fr;
      border-bottom: solid 1px #ccc;
      align-items: center;
      button {
        border-right: solid 1px #ccc;
      }
      a {
        display: grid;
        align-items: center;
        padding-left: 15px;
        text-align: left;
      }
      min-height: 60px;
      & > * {
        height: 100%;
      }
    `)}
  >
    <button
      onClick={() => {
        commands.toggleFavorite(id);
      }}
    >
      <i className="fas fa-bookmark" />
    </button>
    <Link to={`/pages/${id}`}>{name}</Link>
  </div>
);

function ItemsTable(props: { data: FavoritesQuery, favorites: List<string> }) {
  const path = 'data.company.attractions';
  const attractions: AttractionT[] = nested(path, [])(props);
  const { favorites } = props;

  const items = favorites
    .map(v => attractions.find(a => String(a.id) === v))
    .filter(Boolean)
    .map((item, i) => {
      if (!item) return null;
      return <ItemRow key={i} id={item.id} name={item.name} />;
    });

  if (items.isEmpty()) return null;

  return (
    <div
      css={css(`
          border: solid 1px #ccc;
          border-bottom: none;
        `)}
    >
      {items}
    </div>
  );
}

function useCachedQuery(query, opts) {
  const { data, error, loading } = useQuery(query, opts);
  const [newData, setData] = useState(data);
  if (data !== newData && NonEmptyObject.is(data)) {
    setData(data);
  }
  const hasData = NonEmptyObject.is(newData);
  return { data: newData, error, loading, hasData };
}

const DataList = con({
  companyId: 'companyId',
  favorites: ['Cache.Favorites', I.List()],
})((props: { companyId: ID, favorites: List<string> }) => {
  const opts = {
    suspend: false,
    variables: {
      filters: { with_ids: props.favorites.toJS() },
      companyId: props.companyId,
    },
  };
  const qRes = useCachedQuery(FavoritesQueryDef, opts);
  const { data, error, loading, hasData } = qRes;
  const isEmpty = props.favorites.filterNot(v => v === 'undefined').isEmpty();

  if (error) {
    window.onerror(error);
  }

  let items;
  if (isEmpty) {
    items = <EmptySpace />;
  } else if (error) {
    items = (
      <div>
        <p>
          There was a problem loading your favorite places. We have been
          notified of this issue.{' '}
        </p>
        <p>Please try again later.</p>
      </div>
    );
  } else {
    items = <ItemsTable data={data} favorites={props.favorites} />;
  }

  return (
    <React.Fragment>
      {items}
      <Loading show={!hasData && loading} />
      {!isEmpty && hasData && process.env.__DEV__ && (
        <div css={css('margin-top: 20px; text-align: center;')}>
          Get an{' '}
          <button>
            itinerary{' '}
            <i
              className="fa fa-external-link-alt"
              css={css('font-size: 0.725rem;')}
            />
          </button>{' '}
          of your favorite places.
        </div>
      )}
    </React.Fragment>
  );
});

const Component = () => {
  useTitle('LIC Local - Favorites');

  return (
    <Page>
      <Navbar textColor="var(--blue)">Favorites</Navbar>
      <Container>
        <DataList />
      </Container>
    </Page>
  );
};

export default Component
