/*@flow*/
/** @jsx jsx */
import { useEffect, useState } from 'react';
import useTitle from 'lib/useTitle';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import autosize from 'lib/autosize';
import t from 'lib/tcomb-validation';
import Email from 'lib/Email';
import Blank from 'lib/Blank';
import cx from 'classnames';

const Page = styled.div({
  width: '100vw',
  overflow: 'hidden',
});

const Container = styled.div(`
  padding: 30px 40px;
`);

const ValidationContainer = (props: {
  errors: t.Struct[],
  for: string,
  children: boolean => React$Node,
}) => {
  const err = props.errors.find(v => v.path.join('.') === props.for);
  return props.children(Boolean(err));
};

const categories = ['General Inquiry', 'Technical Issues'];

const ValidationFeedback = (props: {
  isValidated: boolean,
  errors: t.Struct[],
  for: string,
}) => {
  const err = props.errors.find(v => v.path.join('.') === props.for);
  if (!err) return null;
  return <div className="invalid-feedback">{err.message}</div>;
};

const NonEmptyString = t.subtype(t.String, v => t.String.is(v) && v.length > 0);

const Feedback = t.subtype(NonEmptyString, v => v && v.length > 10);
Feedback.getValidationErrorMessage = () =>
  'You must type at least 50 characters.';

const Category = t.subtype(NonEmptyString, v => categories.includes(v));
Category.getValidationErrorMessage = () => 'You must select a category.';

const ServerErrors = (props: {
  errorMessage: string[],
  onDismiss: Function,
}) => {
  if (Blank.is(props.errorMessage)) return null;
  return (
    <div className="form-row">
      <div className="alert alert-danger" css={{ width: '100%' }}>
        <div css={css('display: grid; grid-template-columns: 1fr 30px;')}>
          <div>
            {props.errorMessage.map((v, i) => (
              <div key={i}>{v}</div>
            ))}
          </div>
          <div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={props.onDismiss}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const sendEmail = payload => {
  const body = JSON.stringify(payload);
  const url = 'https://3xbatuifd6.execute-api.us-east-1.amazonaws.com/prod';
  const method = 'POST';

  return fetch(url, {
    method,
    body,
    credentials: 'omit',
    mode: 'cors',
  });
};

const sendPayload = payload =>
  sendEmail(payload)
    .then(res => {
      return res;
    })
    .catch(err => {
      if (err.errorMessage && typeof err.errorMessage === 'string') {
        err.errorMessage = JSON.parse(err.errorMessage);
      }
      return Promise.reject(err);
    });

const schema = t.struct({
  email: Email,
  feedback: Feedback,
  category: Category,
});

type Record = { [key: string]: any };
type ValidationError = {
  message: any,
  actual: any,
  expected: any,
  path: Array<string | number>,
};
type RequestStatus = 'NOT_SENT' | 'SENDING' | 'SUCCESS' | 'FAILED';

const Component = () => {
  useTitle('LIC Local - How To');

  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [value, setValue] = useState({});
  const [server, setServer] = useState<{
    errorMessage: string[],
    success: boolean,
  }>({ errorMessage: [], success: false });
  const [isValidated, setValidated] = useState(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('NOT_SENT');

  function updateForm(v) {
    setValidated(false);
    setValue((v2): Record => ({ ...v2, ...v }));
  }

  useEffect(() => {
    const nodes = document.querySelectorAll('textarea');
    if (nodes) {
      autosize(nodes);
    }
  }, []);

  const validateForm = () => {
    const v = t.validate(value, schema);
    setErrors(v.errors);
    setValue(v.value);
    setValidated(true);
    return v;
  };

  const submitForm = () => {
    setRequestStatus('SENDING');

    sendPayload(value)
      .then(() => {
        setRequestStatus('SUCCESS');
      })
      .catch(err => {
        setRequestStatus('FAILED');
        setServer(err);
      });
  };

  const isSending = requestStatus === 'SENDING';
  const isSent = requestStatus === 'SUCCESS';

  const feedbackForm = (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (validateForm().errors.length === 0) {
          submitForm();
        }
      }}
    >
      <ServerErrors
        errorMessage={server.errorMessage}
        onDismiss={() => setServer(v => ({ ...v, errorMessage: [] }))}
      />
      <div className="form-row mb-3">
        <ValidationContainer for="feedback" errors={errors}>
          {isInvalid => (
            <textarea
              className={cx('form-control input-lg', {
                'is-invalid': isInvalid,
                'is-valid': !isInvalid && isValidated,
              })}
              placeholder="Tell us your feedback."
              css={css('min-height: 150px;')}
              onChange={e => {
                updateForm({ feedback: e.target.value });
              }}
              value={value.feedback || ''}
            />
          )}
        </ValidationContainer>
        <ValidationFeedback
          isValidated={isValidated}
          for="feedback"
          errors={errors}
        />
      </div>
      <div className="form-row mb-3">
        <ValidationContainer for="category" errors={errors}>
          {isInvalid => (
            <select
              className={cx('form-control input-lg', {
                'is-invalid': isInvalid,
                'is-valid': !isInvalid && isValidated,
              })}
              onChange={e => {
                updateForm({ category: e.target.value });
              }}
              value={value.category || ''}
            >
              <option> -- Select a category -- </option>
              {categories.map((v, i) => (
                <option key={i} value={v}>
                  {v}
                </option>
              ))}
            </select>
          )}
        </ValidationContainer>
        <ValidationFeedback
          isValidated={isValidated}
          for="category"
          errors={errors}
        />
      </div>
      <div className="form-row mb-3">
        <ValidationContainer for="email" errors={errors}>
          {isInvalid => (
            <input
              className={cx('form-control input-lg', {
                'is-invalid': isInvalid,
                'is-valid': !isInvalid && isValidated,
              })}
              type="text"
              placeholder="Enter your email"
              onChange={e => updateForm({ email: e.target.value })}
              value={value.email || ''}
            />
          )}
        </ValidationContainer>
        <ValidationFeedback
          isValidated={isValidated}
          for="email"
          errors={errors}
        />
      </div>
      <div className="form-row">
        <button
          className={cx('btn btn-lg', { disabled: isSending })}
          type="submit"
          css={css(`
            background-color: var(--blue);
            color: white;
          `)}
        >
          {isSending ? 'Sending...' : 'Send'}
        </button>
      </div>
    </form>
  );

  return (
    <Page>
      <Navbar textColor="var(--blue)">Feedback</Navbar>
      <Container>
        {isSent ? (
          <div
            css={css(`
          height: 200px;
          display: grid;
          align-items: center;
          justify-items: center;
        `)}
          >
            Your feedback has been sent!
          </div>
        ) : (
          feedbackForm
        )}
      </Container>
    </Page>
  );
};

export default Component;
